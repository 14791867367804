import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import { SquaresPlusIcon } from "@heroicons/react/24/outline";
import { SubmitButton } from "components/form/SubmitButton";
import {
  Modal,
  ModalContent,
  ModalIconContainer,
  ModalTitle,
  ModalWrapper
} from "components/Modal";
import { Button } from "components/ui/button";
import { DialogFooter } from "components/ui/dialog";
import { generateActivityAddOnFormSchema } from "helpers/form";
import { wait } from "helpers/helpers";
import { isEmpty } from "lodash";
import { FormTemplateType } from "types/form";
import type { AddOn } from "types/model/activity";
import type { Client } from "types/model/client";

export interface ActivityAddOnFormData {
  name: string;
  price: string;
  restrictSessions?: boolean;
  sessionsCanBeUsedFor?: string[];
}

export const ActivityAddOnsAddEditModal = ({
  isOpen,
  editingAddOnId,
  client,
  onConfirmAddOnAddEdit,
  onCancel,
  setIsOpen
}: {
  isOpen: boolean;
  editingAddOnId: string | null;
  client: Client;
  onCancel: () => void;
  onConfirmAddOnAddEdit: (
    formData: ActivityAddOnFormData,
    addOnId: string | null
  ) => void;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const { renderForm, getState, change, blur } = useFormApi();

  const formValues = getState().values;

  const subFormPrefix = "activityAddOnSubForm";

  const formSchema = generateActivityAddOnFormSchema({
    formTemplate: FormTemplateType.Seamless,
    prefix: subFormPrefix,
    editingAddOn: ((formValues.addOns || []) as AddOn[])?.find(
      item => item._id === editingAddOnId
    ),
    client
  });

  async function handleSubmit() {
    const { valid: isValid, values, errors } = getState();
    const formData = values[subFormPrefix];
    const formErrors = errors?.[subFormPrefix];

    // triggers blur on all fields so meta touched is set to true
    if (!isValid && formErrors) {
      for (const key in formErrors) {
        blur(`${subFormPrefix}.${key}`);
      }

      return;
    }

    const hasErrors = !isEmpty(formErrors);

    if (hasErrors) return;

    setIsOpen(false);

    await wait(300);

    onConfirmAddOnAddEdit(formData, editingAddOnId);
    change(subFormPrefix, undefined);
  }

  async function handleClose() {
    setIsOpen(false);

    await wait(300);

    onCancel();
    change(subFormPrefix, undefined);
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      className="w-[500px]"
      cy="modal-activity-addons-add-edit"
    >
      <ModalWrapper>
        <ModalIconContainer>
          <SquaresPlusIcon
            className="h-6 w-6 text-indigo-600"
            aria-hidden="true"
          />
        </ModalIconContainer>
        <ModalContent>
          <ModalTitle>
            {editingAddOnId ? "Edit add-on" : "Add new add-on"}
          </ModalTitle>
          {formSchema && (
            <div className="mb-4">{renderForm(formSchema.fields)}</div>
          )}
        </ModalContent>
      </ModalWrapper>
      <DialogFooter>
        <Button type="button" variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <SubmitButton type="button" onClick={handleSubmit}>
          OK
        </SubmitButton>
      </DialogFooter>
    </Modal>
  );
};
