import { PlusIcon as PlusIconSolidMini } from "@heroicons/react/20/solid";
import { CheckIcon, PlusIcon } from "@heroicons/react/24/outline";
import { ActivityFiltersNoDateRange } from "components/admin/ActivityFiltersNoDateRange";
import { SubmitButton } from "components/form/SubmitButton";
import { Loader } from "components/Loader";
import {
  Modal,
  ModalContent,
  ModalIconContainer,
  ModalTitle,
  ModalWrapper
} from "components/Modal";
import { Button } from "components/ui/button";
import { DialogFooter } from "components/ui/dialog";
import { getActivityTitle, getActivityVenueName, wait } from "helpers/helpers";
import useActivityGroupsFullList from "hooks/useActivityGroupsFullList";
import { uniq } from "lodash";
import { useState } from "react";
import type {
  ActivityGroup,
  ActivityGroupFullListItem
} from "types/model/activity-group";
import type { Field } from "types/model/field";
import type { FieldFilters } from "types/model/field-data";
import type { Venue } from "types/model/venue";

export const ActivityGroupsSelectModal = ({
  id,
  venues,
  fields,
  isOpen,
  selectedActivityGroupIds,
  documentsKey,
  setSelectedActivityGroupIds,
  resetSelectedActivityGroupIds,
  handleUpdateActivityGroups,
  setIsOpen
}: {
  id: string;
  venues: Venue[];
  fields: Field[];
  isOpen: boolean;
  selectedActivityGroupIds: string[];
  documentsKey: string;
  setSelectedActivityGroupIds: (activityGroupIds: string[]) => void;
  resetSelectedActivityGroupIds: () => void;
  handleUpdateActivityGroups: (activityGroupIds: string[]) => void;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const [filters, setFilters] = useState<FieldFilters>({});
  const activityGroupsFullListQueryInfo = useActivityGroupsFullList(
    JSON.stringify(filters)
  );

  function handleAddActivityGroup(activityGroupId: string) {
    setSelectedActivityGroupIds([...selectedActivityGroupIds, activityGroupId]);
  }

  function handleRemoveActivityGroup(activityGroupId: string) {
    const updatedActivityGroupIds = selectedActivityGroupIds.filter(
      id => id !== activityGroupId
    );
    setSelectedActivityGroupIds(updatedActivityGroupIds);
  }

  function getHasActivityGroupAlreadyBeenAssigned(
    activityGroup: ActivityGroup | ActivityGroupFullListItem,
    id: string,
    documentsKey: string
  ) {
    const hasActivityGroupAlreadyBeenAssignedToAnotherPass = (
      activityGroup[
        documentsKey as keyof (ActivityGroup | ActivityGroupFullListItem)
      ] as ActivityGroupFullListItem[]
    ).some(item => item._id !== id);

    return hasActivityGroupAlreadyBeenAssignedToAnotherPass;
  }

  function handleSelectAllActivityGroups(
    activityGroups: ActivityGroupFullListItem[]
  ) {
    const ids: string[] = activityGroups
      .filter(
        activityGroup =>
          !getHasActivityGroupAlreadyBeenAssigned(
            activityGroup,
            id,
            documentsKey
          )
      )
      .map(activityGroup => activityGroup._id);
    setSelectedActivityGroupIds(uniq([...selectedActivityGroupIds, ...ids]));
  }

  function handleUnselectAllActivityGroups(
    activityGroups: ActivityGroupFullListItem[]
  ) {
    const ids: string[] = activityGroups.map(
      activityGroup => activityGroup._id
    );
    const updatedActivityGroupIds = selectedActivityGroupIds.filter(
      id => !ids.includes(id)
    );
    setSelectedActivityGroupIds(updatedActivityGroupIds);
  }

  async function handleSubmit() {
    handleUpdateActivityGroups(selectedActivityGroupIds);
    setIsOpen(false);

    await wait(300);

    setFilters({});
  }

  async function handleClose() {
    setIsOpen(false);
    await wait(300);

    resetSelectedActivityGroupIds();
    setFilters({});
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} className="w-[640px]">
      <ModalWrapper>
        <ModalIconContainer>
          <PlusIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
        </ModalIconContainer>
        <ModalContent>
          <ModalTitle className="text-center text-lg font-medium leading-6 text-gray-900 sm:text-left">
            Select Activities
          </ModalTitle>
          <div className="mb-4 w-full">
            <ActivityFiltersNoDateRange
              filterFields={fields}
              filters={filters}
              venuesList={venues}
              visibilityKey="useForAdminFiltering"
              inModal={true}
              innerClassName="py-5"
              handleSetFilters={setFilters}
              className="[&>div:first-child]:pt-[0px!important]"
            />
            {activityGroupsFullListQueryInfo.isLoading ? (
              <Loader />
            ) : activityGroupsFullListQueryInfo.data ? (
              <>
                {activityGroupsFullListQueryInfo.data.length >= 1 ? (
                  <>
                    <div className="text-right text-sm">
                      <a
                        className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500 focus:underline focus:outline-none"
                        onClick={() =>
                          handleSelectAllActivityGroups(
                            activityGroupsFullListQueryInfo.data || []
                          )
                        }
                      >
                        Select all
                      </a>
                      <span className="text-gray-700">{" | "}</span>
                      <a
                        className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500 focus:underline focus:outline-none"
                        onClick={() =>
                          handleUnselectAllActivityGroups(
                            activityGroupsFullListQueryInfo.data || []
                          )
                        }
                      >
                        Unselect all
                      </a>
                    </div>
                    <ul
                      role="list"
                      className="mt-2 max-h-bananas divide-y divide-gray-200 overflow-y-scroll border-b border-t border-gray-200 pr-4 sm:max-h-oranges"
                    >
                      {activityGroupsFullListQueryInfo.data.map(
                        activityGroup => (
                          <li
                            key={activityGroup._id}
                            className="flex items-center justify-between space-x-3 py-4 text-left"
                          >
                            <div className="flex-grow">
                              <div className="text-sm font-medium text-gray-700">
                                {getActivityTitle(activityGroup)}
                              </div>
                              <div className="text-sm text-gray-500">
                                {getActivityVenueName(activityGroup)}
                              </div>
                              <div className="text-sm text-gray-500">
                                {activityGroup.activityGroupStartEnd}
                              </div>
                            </div>
                            <div className="flex-shrink">
                              {getHasActivityGroupAlreadyBeenAssigned(
                                activityGroup,
                                id,
                                documentsKey
                              ) ? (
                                <span className="block text-right text-sm leading-4 text-gray-500">
                                  Another pass already applied
                                </span>
                              ) : selectedActivityGroupIds.includes(
                                  activityGroup._id
                                ) ? (
                                <button
                                  type="button"
                                  className="inline-flex items-center rounded-full border border-transparent bg-green-50 px-3 py-2 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                  onClick={() =>
                                    handleRemoveActivityGroup(activityGroup._id)
                                  }
                                >
                                  <CheckIcon
                                    className="-ml-1 mr-0.5 h-5 w-5 text-green-400"
                                    aria-hidden="true"
                                  />
                                  <span className="text-sm font-medium text-green-900">
                                    Added
                                  </span>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="inline-flex items-center rounded-full border border-transparent bg-gray-100 px-3 py-2 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  onClick={() =>
                                    handleAddActivityGroup(activityGroup._id)
                                  }
                                >
                                  <PlusIconSolidMini
                                    className="-ml-1 mr-0.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span className="text-sm font-medium text-gray-900">
                                    Add
                                  </span>
                                </button>
                              )}
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                  </>
                ) : (
                  <p className="text-sm text-gray-500">No activities found</p>
                )}
              </>
            ) : null}
          </div>
        </ModalContent>
      </ModalWrapper>
      <DialogFooter className="sticky bottom-0">
        <Button type="button" variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <SubmitButton type="button" onClick={handleSubmit}>
          OK
        </SubmitButton>
      </DialogFooter>
    </Modal>
  );
};
