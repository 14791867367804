import { Dialog, Transition } from "@headlessui/react";
import { UserGroupIcon } from "@heroicons/react/24/outline";
import { FieldValue } from "components/FieldValue";
import { SubmitButton } from "components/form/SubmitButton";
import { EditAttendeeForm } from "components/site/EditAttendeeForm";
import { getAttendeeFullName, wait } from "helpers/helpers";
import useSaveAttendee from "hooks/useSaveAttendee";
import { Fragment, useRef, useState } from "react";
import type { FormDataItem } from "types/form";
import type { Attendee } from "types/model/attendee";
import type { Client } from "types/model/client";
import type { Field } from "types/model/field";
import type { FieldData } from "types/model/field-data";
import type { Tab } from "types/model/tab";
import { cn } from "utils/cn";

interface ModalTab extends Tab {
  current: boolean;
}

const initialTabItems: ModalTab[] = [
  {
    name: "View",
    value: "view",
    current: true
  },
  {
    name: "Edit",
    value: "edit",
    current: false
  }
];

export const AdminBookingViewEditAttendeeDetailsModal = ({
  attendee,
  attendeeFields,
  activityGroupId,
  client,
  isOpen,
  setIsOpen
}: {
  attendee: Attendee | null;
  attendeeFields: Field[];
  activityGroupId: string;
  client: Client;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [saveAttendee, saveAttendeeInfo] = useSaveAttendee();

  const [tabs, setTabs] = useState<ModalTab[]>(initialTabItems);

  function handleSetCurrentTab(tabValue: string) {
    setTabs(
      tabs.map(tab => {
        if (tab.value === tabValue) {
          return {
            ...tab,
            current: true
          };
        }
        return {
          ...tab,
          current: false
        };
      })
    );
  }

  const applicableAttendeeFields = attendeeFields.filter(field => {
    // Doing this for the time being as a lot of existing fields don't have this value set
    const appliesToAllActivityGroups =
      field.appliesToAllActivityGroups !== false;

    return (
      appliesToAllActivityGroups ||
      field.activityGroups?.includes(activityGroupId)
    );
  });

  async function handleSubmit(formData: FormDataItem[]) {
    await saveAttendee({ id: attendee?._id, formData });
    setIsOpen(false);
    await wait(300);
    setTabs(initialTabItems);
  }

  async function handleClose() {
    setIsOpen(false);
    await wait(300);
    setTabs(initialTabItems);
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={wrapperRef}
        open={isOpen}
        onClose={handleClose}
        data-cy="view-attendance-details-modal"
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={handleClose}
            />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block min-w-full transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:min-w-min sm:max-w-lg sm:align-middle lg:max-w-3xl">
              <div
                className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4"
                ref={wrapperRef}
              >
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    <UserGroupIcon className="h-6 w-6 text-indigo-600" />
                  </div>
                  {attendee && (
                    <div className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="mb-4 text-lg font-medium leading-6 text-gray-900 sm:mb-2"
                        data-cy="modal-title"
                      >
                        {getAttendeeFullName(attendee)}
                      </Dialog.Title>
                      <div>
                        <div className="sm:hidden">
                          <label htmlFor="tabs" className="sr-only">
                            Select a tab
                          </label>
                          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                          <select
                            id="tabs"
                            name="tabs"
                            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                            value={tabs.find(tab => tab.current)?.value}
                            onChange={e => handleSetCurrentTab(e.target.value)}
                          >
                            {tabs.map(tab => (
                              <option key={tab.value} value={tab.value}>
                                {tab.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="hidden sm:block">
                          <div className="border-b border-gray-200">
                            <nav
                              className="-mb-px flex space-x-8"
                              aria-label="Tabs"
                            >
                              {tabs.map(tab => (
                                <a
                                  key={tab.name}
                                  className={cn(
                                    tab.current
                                      ? "border-indigo-500 text-indigo-600"
                                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                    "cursor-pointer whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
                                  )}
                                  onClick={() => handleSetCurrentTab(tab.value)}
                                >
                                  {tab.name}
                                </a>
                              ))}
                            </nav>
                          </div>
                        </div>
                      </div>
                      {tabs.find(tab => tab.current)?.value === "view" ? (
                        <div className="mb-4 text-left sm:mt-2">
                          <dl className="divide-y divide-gray-200">
                            {applicableAttendeeFields.map(field => (
                              <div
                                key={field._id}
                                className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
                                data-cy="selected-attendee-field"
                              >
                                <dt className="text-sm font-medium text-gray-500">
                                  {field.title}
                                  {field.required && "*"}
                                </dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {attendee.fieldData.some(
                                    fieldDateItem =>
                                      fieldDateItem.field?._id === field._id
                                  ) ? (
                                    <FieldValue
                                      fieldDataItem={
                                        attendee.fieldData.find(
                                          fieldDateItem =>
                                            fieldDateItem.field?._id ===
                                            field._id
                                        ) as FieldData
                                      }
                                      client={client}
                                    />
                                  ) : field.required ? (
                                    <p className="text-red-500">
                                      Please complete this required field
                                    </p>
                                  ) : null}
                                </dd>
                              </div>
                            ))}
                          </dl>
                        </div>
                      ) : (
                        <div className="mb-4 mt-6 text-left">
                          <EditAttendeeForm
                            activityGroupId={activityGroupId}
                            attendee={attendee}
                            attendeeFields={attendeeFields}
                            client={client}
                            isInModal={true}
                            onCancel={handleClose}
                            handleSubmit={handleSubmit}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {tabs.find(tab => tab.current)?.value === "edit" && (
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <SubmitButton
                    type="submit"
                    form="attendeeForm"
                    disabled={saveAttendeeInfo.isLoading}
                    isLoading={saveAttendeeInfo.isLoading}
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Submit
                  </SubmitButton>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
