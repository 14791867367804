import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { Button } from "components/ui/button";
import { XIcon } from "lucide-react";
import type { ReactNode } from "react";
import type { Children } from "types";
import { cn } from "utils/cn";

const alertVariants = cva("rounded-md p-4 flex items-start", {
  variants: {
    variant: {
      yellow: "bg-yellow-50",
      purple: "bg-purple-50",
      red: "bg-red-50",
      blue: "bg-blue-50",
      green: "bg-green-50"
    }
  }
});

const iconVariants = cva("h-5 w-5", {
  variants: {
    variant: {
      yellow: "text-yellow-400",
      purple: "text-purple-400",
      red: "text-red-400",
      blue: "text-blue-400",
      green: "text-green-600"
    }
  }
});

const textVariants = cva("text-sm font-medium", {
  variants: {
    variant: {
      yellow: "text-yellow-700",
      purple: "text-purple-700",
      red: "text-red-700",
      blue: "text-blue-700",
      green: "text-green-700"
    }
  }
});

const closeButtonVariants = cva(
  "inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 transition",
  {
    variants: {
      variant: {
        yellow:
          "text-yellow-500 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50",
        purple:
          "text-purple-500 hover:bg-purple-100 focus:ring-purple-600 focus:ring-offset-purple-50",
        red: "text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50",
        blue: "text-blue-500 hover:bg-blue-100 focus:ring-blue-600 focus:ring-offset-blue-50",
        green:
          "text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50"
      }
    }
  }
);

type AlertVariants = VariantProps<typeof alertVariants>;

/**
 * An alert component that displays a message to the user with color variants.
 */
export const Alert = ({
  variant = "yellow",
  className,
  textClassName,
  icon,
  showDismissButton,
  onClose,
  children,
  cy
}: Children &
  AlertVariants & {
    className?: string;
    textClassName?: string;
    icon?: ReactNode;
    showDismissButton?: boolean;
    onClose?: () => void;
    cy?: string;
  }) => {
  return (
    <div
      className={cn("relative", alertVariants({ variant }), className)}
      {...(cy && { "data-cy": cy })}
    >
      <div className="flex-shrink-0">
        {icon || (
          <ExclamationCircleIcon
            aria-hidden="true"
            className={iconVariants({ variant })}
          />
        )}
      </div>
      <div className="ml-3">
        <p className={cn(textVariants({ variant }), textClassName)}>
          {children}
        </p>
      </div>
      {showDismissButton && (
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <Button
              type="button"
              variant="unstyled"
              className={cn(closeButtonVariants({ variant }))}
              onClick={() => onClose?.()}
            >
              <span className="sr-only">Dismiss</span>
              <XIcon aria-hidden="true" className="h-5 w-5" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
