import type { Schema } from "@data-driven-forms/react-form-renderer";
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import { Dialog, Transition } from "@headlessui/react";
import {
  QuestionMarkCircleIcon,
  TicketIcon
} from "@heroicons/react/24/outline";
import { SpotlightHelpInfo } from "components/admin/SpotlightHelpInfo";
import { SubmitButton } from "components/form/SubmitButton";
import { generateActivityTicketFormSchema } from "helpers/form";
import { formatCurrency, wait } from "helpers/helpers";
import { getScreenFromWidth } from "helpers/utils";
import useRelativeElementPositionByAttribute from "hooks/useRelativeElementPositionByAttribute";
import { useWindowWidth } from "hooks/useWindowWidth";
import { Fragment, useEffect, useRef, useState } from "react";
import { FormTemplateType } from "types/form";
import { Screen } from "types/general";
import type { Ticket } from "types/model/activity";
import { TicketType } from "types/model/activity";
import type { Client } from "types/model/client";
import type { FormErrors } from "types/model/form";
import type { SubscriptionPlan } from "types/model/subscription-plan";
import { cn } from "utils/cn";

export interface ActivityTicketFormData {
  name: string;
  type: TicketType;
  price: string;
  shouldProRata?: boolean;
  specifyTicketLimit?: boolean;
  placeLimit?: string;
  restrictSessions?: boolean;
  sessionsCanBeUsedFor?: string[];
  allowMidMonthBookings?: boolean;
  coverFeePerSession?: string;
  subscriptionPlanId?: string;
  overrideSessionTimeDisplay?: boolean;
  overrideStartTime?: string;
  overrideEndTime?: string;
}

const subFormPrefix = "activityTicketSubForm";

export const ActivityTicketsAddEditModal = ({
  isOpen,
  editingTicketId,
  subscriptionPlansData,
  allTicketsData,
  client,
  onConfirmTicketAddEdit,
  onCancel,
  setIsOpen
}: {
  isOpen: boolean;
  editingTicketId: string | null;
  subscriptionPlansData: SubscriptionPlan[];
  allTicketsData: Ticket<SubscriptionPlan>[];
  client: Client;
  onCancel: () => void;
  onConfirmTicketAddEdit: (
    formData: ActivityTicketFormData,
    ticketId: string | null
  ) => void;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const spotlightHelpInfoRef = useRef<HTMLDivElement>(null);

  const [helpStepIndex, setHelpStepIndex] = useState(0);
  const [isHelpOpen, setIsHelpOpen] = useState(
    client.onboardingInfo && !client.onboardingInfo.hasCreatedActivity
  );

  const width = useWindowWidth();

  const [allSessionsOptionPosition, updatePosition] =
    useRelativeElementPositionByAttribute(
      '[data-element="modal-activity-tickets-add-edit-inner"]',
      '[data-element="ticket-type-option-all-sessions"]'
    );
  const [singleSessionOptionPosition, updatePosition1] =
    useRelativeElementPositionByAttribute(
      '[data-element="modal-activity-tickets-add-edit-inner"]',
      '[data-element="ticket-type-option-single-session"]'
    );
  const [subscriptionOptionPosition, updatePosition2] =
    useRelativeElementPositionByAttribute(
      '[data-element="modal-activity-tickets-add-edit-inner"]',
      '[data-element="ticket-type-option-subscription"]'
    );

  const screen = getScreenFromWidth(width);
  const isCompactLayout = [Screen.XS, Screen.SM, Screen.MD, Screen.LG].includes(
    screen
  );
  const helpSpotlightWidth = isCompactLayout ? 320 : 352;

  const [helpSpotlightTopPositions, setHelpSpotlightTopPositions] = useState([
    0, 0, 0, 0
  ]);

  useEffect(() => {
    const allSessionsOptionHeight = allSessionsOptionPosition.height;
    const singleSessionOptionHeight = singleSessionOptionPosition.height;
    const subscriptionOptionHeight = subscriptionOptionPosition.height;

    const spotlightHeight = spotlightHelpInfoRef.current?.offsetHeight || 0;

    let position0: number,
      position1: number,
      position2: number,
      position3: number;

    if (isCompactLayout) {
      const upArrowHeight = 8;
      position0 = allSessionsOptionPosition.top;
      position1 =
        allSessionsOptionPosition.top +
        allSessionsOptionPosition.height +
        upArrowHeight;
      position2 =
        singleSessionOptionPosition.top +
        singleSessionOptionPosition.height +
        upArrowHeight;
      position3 =
        subscriptionOptionPosition.top +
        subscriptionOptionPosition.height +
        upArrowHeight;
    } else {
      position0 = -23;
      position1 =
        allSessionsOptionPosition.top +
        allSessionsOptionHeight / 2 -
        spotlightHeight / 2;
      position2 =
        singleSessionOptionPosition.top +
        singleSessionOptionHeight / 2 -
        spotlightHeight / 2;
      position3 =
        subscriptionOptionPosition.top +
        subscriptionOptionHeight / 2 -
        spotlightHeight / 2;
    }

    setHelpSpotlightTopPositions([position0, position1, position2, position3]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSessionsOptionPosition]);

  const updatePositions = () => {
    updatePosition();
    updatePosition1();
    updatePosition2();
  };

  const { renderForm, getState, change, blur } = useFormApi();

  const formValues = getState().values;

  const [formSchema, setFormSchema] = useState<Schema>();

  useEffect(() => {
    const formSchema = generateActivityTicketFormSchema({
      formTemplate: FormTemplateType.Seamless,
      prefix: subFormPrefix,
      editingTicket: formValues.tickets?.find(
        (item: { _id: string }) => item._id === editingTicketId
      ),
      allTicketsData,
      subscriptionPlansData,
      client
    });

    setFormSchema(formSchema);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingTicketId]);

  async function handleSubmit() {
    const { valid: isValid, values, errors } = getState();
    const formData = values[subFormPrefix];
    const formErrors = errors?.[subFormPrefix];

    // triggers blur on all fields so meta touched is set to true
    if (!isValid && formErrors) {
      for (const key in formErrors) {
        blur(`${subFormPrefix}.${key}`);
      }

      return;
    }

    // Added this check in. Sometimes existing subscriptions plans are still shown in the dropdown
    // even though they have been used by other tickets. Think need to work out how to use form subscription
    // with the renderForm() function.
    if (formData.type === TicketType.Subscription && allTicketsData) {
      const subscriptionPlansIdsUsedByOtherTickets = allTicketsData
        .filter(
          ticket =>
            ticket.type === TicketType.Subscription &&
            ticket._id !== editingTicketId
        )
        .map(ticket => ticket.subscriptionPlan?._id);

      if (
        subscriptionPlansIdsUsedByOtherTickets.includes(
          formData.subscriptionPlanId
        )
      ) {
        (errors as FormErrors)[subFormPrefix] =
          (errors as FormErrors)[subFormPrefix] || {};

        ((errors as FormErrors)[subFormPrefix] as FormErrors)[
          "subscriptionPlanId"
        ] =
          "This subscription plan has already been used by another ticket. Please select another one.";

        blur(`${subFormPrefix}.subscriptionPlanId`);

        return;
      }
    }

    setIsOpen(false);

    await wait(300);

    onConfirmTicketAddEdit(formData, editingTicketId);
    handleSetIsHelpOpen(false);
    change(subFormPrefix, undefined);
  }

  async function handleClose() {
    setIsOpen(false);

    await wait(300);

    onCancel();
    handleSetIsHelpOpen(false);
    change(subFormPrefix, undefined);
  }

  function handleSetIsHelpOpen(isOpen: boolean) {
    if (!isOpen) {
      setHelpStepIndex(0);
    }

    updatePositions();
    setIsHelpOpen(isOpen);
  }

  function handleSetStepIndex(index: number) {
    setHelpStepIndex(index);
    updatePositions();
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={wrapperRef}
        open={isOpen}
        onClose={handleClose}
        data-cy="modal-activity-tickets-add-edit"
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={handleClose}
            />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={cn(
                "inline-block min-w-full transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:min-w-min sm:max-w-lg sm:align-middle",
                isHelpOpen && "overflow-visible"
              )}
              data-cy="modal-activity-tickets-add-edit"
            >
              <div data-element="modal-activity-tickets-add-edit-inner">
                {isHelpOpen && isCompactLayout && (
                  <div className="fixed inset-0 z-40 rounded-lg bg-black bg-opacity-20 transition-opacity" />
                )}
                <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4" ref={wrapperRef}>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                      <TicketIcon
                        className="h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 w-full sm:ml-4 sm:mt-0">
                      <div className="flex items-center justify-center gap-2 sm:justify-between">
                        <Dialog.Title
                          as="h3"
                          className="text-center text-lg font-medium leading-6 text-gray-900 sm:text-left"
                        >
                          {editingTicketId ? "Edit ticket" : "Add new ticket"}
                        </Dialog.Title>
                        <a
                          className="cursor-pointer rounded-full border border-gray-300 bg-white p-1 hover:bg-gray-50"
                          onClick={() => handleSetIsHelpOpen(!isHelpOpen)}
                        >
                          <QuestionMarkCircleIcon className="h-6 w-6 text-gray-500" />
                        </a>
                      </div>
                      {formSchema && (
                        <div className="my-4">
                          {renderForm(formSchema.fields)}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="rounded-b-lg bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <SubmitButton
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleSubmit}
                  >
                    OK
                  </SubmitButton>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              {isHelpOpen && (
                <div
                  className={cn(
                    "absolute pl-6",
                    isHelpOpen ? "pointer-events-auto" : "pointer-events-none"
                  )}
                  style={{
                    width: helpSpotlightWidth,
                    top: helpSpotlightTopPositions[helpStepIndex],
                    ...(!isCompactLayout && {
                      right: -helpSpotlightWidth
                    }),
                    ...(isCompactLayout && {
                      width: helpSpotlightWidth,
                      left: -48,
                      right: 0,
                      marginLeft: "auto",
                      marginRight: "auto"
                    })
                  }}
                >
                  <SpotlightHelpInfo
                    width={helpSpotlightWidth}
                    helpStepIndex={helpStepIndex}
                    totalSteps={4}
                    arrowDirection={!isCompactLayout ? "left" : "up"}
                    setHelpStepIndex={handleSetStepIndex}
                    handleClose={() => handleSetIsHelpOpen(false)}
                    ref={spotlightHelpInfoRef}
                  >
                    {helpStepIndex === 0 && (
                      <p className="mt-1 text-sm text-gray-500">
                        There are three ticket types that you can create.
                      </p>
                    )}
                    {helpStepIndex === 1 && (
                      <div>
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-medium">
                            &quot;All Sessions&quot; Ticket:
                          </span>
                          <br />
                          Enrolls attendee to all sessions in this activity. If
                          &apos;Pro rata&apos; is enabled, price adjusts based
                          on remaining sessions.
                        </p>
                        <p className="mt-2 text-sm text-gray-500">
                          Example:{" "}
                          {formatCurrency({
                            rawAmount: 20000,
                            currency: client.currency,
                            includeCents: false
                          })}{" "}
                          for a 5 session activity; after the first session has
                          passed, users pay{" "}
                          {formatCurrency({
                            rawAmount: 16000,
                            currency: client.currency,
                            includeCents: false
                          })}{" "}
                          for the remaining 4 sessions.
                        </p>
                      </div>
                    )}
                    {helpStepIndex === 2 && (
                      <div>
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-medium">
                            &quot;Single Session&quot; Ticket:
                          </span>
                          <br />
                          Created for each session; attendees select specific
                          sessions to book.
                        </p>
                        <p className="mt-2 text-sm text-gray-500">
                          No need to create separate tickets for each session;
                          the system handles it automatically.
                        </p>
                      </div>
                    )}
                    {helpStepIndex === 3 && (
                      <div>
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-medium">
                            &quot;Subscription&quot; Ticket:
                          </span>
                          <br />
                          Books attendee into all sessions via monthly
                          subscription or payment plan. Requires a subscription
                          plan to be set up. For more details on Subscription
                          tickets, refer to our{" "}
                          <a
                            href="https://help.pembee.app/article/54-subscription-and-payment-plan"
                            target="_blank"
                            rel="noreferrer"
                            className="text-indigo-600 hover:text-indigo-900 focus:underline focus:outline-none"
                          >
                            help article
                          </a>
                          .
                        </p>
                      </div>
                    )}
                  </SpotlightHelpInfo>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
