import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ExclamationTriangleIcon
} from "@heroicons/react/24/outline";
import { SubmitButton } from "components/form/SubmitButton";
import { FormError } from "components/FormError";
import { wait } from "helpers/helpers";
import useUpdateStripeConnectedAccountStatus from "hooks/useUpdateStripeConnectedAccountStatus";
import { Fragment, useRef, useState } from "react";
import type { StripeConnectedAccountWithStripeAccount } from "types/model/payment";
import { cn } from "utils/cn";

export const UpdateStripeConnectedAccountStatusConfirmModal = ({
  connectedAccount,
  paymentMethodId,
  clientHasActiveSubscriptions,
  isOpen,
  setIsOpen
}: {
  connectedAccount: StripeConnectedAccountWithStripeAccount | null;
  paymentMethodId: string | null;
  clientHasActiveSubscriptions: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const bodyRef = useRef(null);

  const [
    updateStripeConnectedAccountStatus,
    updateStripeConnectedAccountStatusInfo
  ] = useUpdateStripeConnectedAccountStatus();

  const [isSuccess, setIsSuccess] = useState(false);

  async function handleSubmit() {
    if (!connectedAccount || !paymentMethodId) return;

    const response = await updateStripeConnectedAccountStatus({
      accountId: connectedAccount.accountId,
      paymentMethodId,
      connected: !connectedAccount.connected
    });

    if (response?.data.success === true) {
      setIsSuccess(true);
    }
  }

  async function handleClose() {
    setIsOpen(false);

    await wait(300);

    setIsSuccess(false);

    updateStripeConnectedAccountStatusInfo.reset();
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={bodyRef}
        open={isOpen}
        onClose={handleClose}
        data-cy="modal-update-stripe-connected-account-status"
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={handleClose}
            />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block min-w-full transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:min-w-min sm:max-w-lg sm:align-middle">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div
                    className={cn(
                      "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10",
                      isSuccess && "bg-green-100",
                      clientHasActiveSubscriptions && "bg-red-100",
                      !isSuccess &&
                        !clientHasActiveSubscriptions &&
                        "bg-indigo-100"
                    )}
                  >
                    {isSuccess ? (
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    ) : clientHasActiveSubscriptions ? (
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                        width="64"
                        height="64"
                        fill="currentColor"
                        className="h-6 w-6 text-indigo-600"
                      >
                        <path
                          d="M111.328 15.602c0-4.97-2.415-8.9-7.013-8.9s-7.423 3.924-7.423 8.863c0 5.85 3.32 8.8 8.036 8.8 2.318 0 4.06-.528 5.377-1.26V19.22a10.246 10.246 0 0 1-4.764 1.075c-1.9 0-3.556-.67-3.774-2.943h9.497a39.64 39.64 0 0 0 .063-1.748zm-9.606-1.835c0-2.186 1.35-3.1 2.56-3.1s2.454.906 2.454 3.1zM89.4 6.712a5.434 5.434 0 0 0-3.801 1.509l-.254-1.208h-4.27v22.64l4.85-1.032v-5.488a5.434 5.434 0 0 0 3.444 1.265c3.472 0 6.64-2.792 6.64-8.957.003-5.66-3.206-8.73-6.614-8.73zM88.23 20.1a2.898 2.898 0 0 1-2.288-.906l-.03-7.2a2.928 2.928 0 0 1 2.315-.96c1.775 0 2.998 2 2.998 4.528.003 2.593-1.198 4.546-2.995 4.546zM79.25.57l-4.87 1.035v3.95l4.87-1.032z"
                          fillRule="evenodd"
                        />
                        <path d="M74.38 7.035h4.87V24.04h-4.87z" />
                        <path
                          d="M69.164 8.47l-.302-1.434h-4.196V24.04h4.848V12.5c1.147-1.5 3.082-1.208 3.698-1.017V7.038c-.646-.232-2.913-.658-4.048 1.43zm-9.73-5.646L54.698 3.83l-.02 15.562c0 2.87 2.158 4.993 5.038 4.993 1.585 0 2.756-.302 3.405-.643v-3.95c-.622.248-3.683 1.138-3.683-1.72v-6.9h3.683V7.035h-3.683zM46.3 11.97c0-.758.63-1.05 1.648-1.05a10.868 10.868 0 0 1 4.83 1.25V7.6a12.815 12.815 0 0 0-4.83-.888c-3.924 0-6.557 2.056-6.557 5.488 0 5.37 7.375 4.498 7.375 6.813 0 .906-.78 1.186-1.863 1.186-1.606 0-3.68-.664-5.307-1.55v4.63a13.461 13.461 0 0 0 5.307 1.117c4.033 0 6.813-1.992 6.813-5.485 0-5.796-7.417-4.76-7.417-6.943zM13.88 9.515c0-1.37 1.14-1.9 2.982-1.9A19.661 19.661 0 0 1 25.6 9.876v-8.27A23.184 23.184 0 0 0 16.862.001C9.762.001 5 3.72 5 9.93c0 9.716 13.342 8.138 13.342 12.326 0 1.638-1.4 2.146-3.37 2.146-2.905 0-6.657-1.202-9.6-2.802v8.378A24.353 24.353 0 0 0 14.973 32C22.27 32 27.3 28.395 27.3 22.077c0-10.486-13.42-8.613-13.42-12.56z"
                          fillRule="evenodd"
                        />
                      </svg>
                    )}
                  </div>
                  <div
                    className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left"
                    ref={bodyRef}
                  >
                    {connectedAccount && (
                      <>
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                          data-cy="modal-update-stripe-connected-account-status-title"
                        >
                          {connectedAccount.connected
                            ? "Disconnect Stripe account"
                            : "Connect Stripe account"}
                        </Dialog.Title>
                        {updateStripeConnectedAccountStatusInfo.error && (
                          <FormError className="mt-4">
                            {
                              updateStripeConnectedAccountStatusInfo.error
                                .message
                            }
                          </FormError>
                        )}
                        <div
                          className="mt-2"
                          data-cy="modal-update-stripe-connected-account-status-body"
                        >
                          {isSuccess ? (
                            <>
                              {connectedAccount.connected ? (
                                <p className="text-center text-sm text-gray-500 sm:text-left">
                                  Stripe account successfully disconnected.
                                </p>
                              ) : (
                                <p className="text-center text-sm text-gray-500 sm:text-left">
                                  Stripe account successfully connected.
                                </p>
                              )}
                            </>
                          ) : (
                            <div className="mt-4">
                              {clientHasActiveSubscriptions &&
                              connectedAccount.connected ? (
                                <p className="text-sm text-gray-500">
                                  This connected account is being used for
                                  subscriptions so it cannot be disconnected.
                                  Please delete any Subscription plans and
                                  cancel all User subscriptions first.
                                </p>
                              ) : connectedAccount.connected ? (
                                <p className="text-sm text-gray-500">
                                  Are you sure you wish to disconnect this
                                  Stripe account?
                                </p>
                              ) : (
                                <p className="text-sm text-gray-500">
                                  Are you sure you wish to connect this Stripe
                                  account?
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                {!isSuccess && !clientHasActiveSubscriptions && (
                  <SubmitButton
                    type="button"
                    disabled={updateStripeConnectedAccountStatusInfo.isLoading}
                    isLoading={updateStripeConnectedAccountStatusInfo.isLoading}
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleSubmit}
                  >
                    OK
                  </SubmitButton>
                )}
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={handleClose}
                >
                  {isSuccess || clientHasActiveSubscriptions
                    ? "Close"
                    : "Cancel"}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
