import { FormRenderer } from "@data-driven-forms/react-form-renderer";
import {
  AttendeeModalFormTemplate,
  componentMapper,
  FormTemplate
} from "components/form/FormTemplate";
import { generateAttendeeFormSchema } from "helpers/form";
import useUserSaveAttendee from "hooks/useUserSaveAttendee";
import type { FormDataItem } from "types/form";
import type { ActivityGroup } from "types/model/activity-group";
import type { Attendee } from "types/model/attendee";
import type { Client } from "types/model/client";
import type { Field } from "types/model/field";

export const EditAttendeeForm = ({
  activityGroupId,
  attendee,
  attendeeFields,
  client,
  isInModal = false,
  handleSubmit,
  onCreate,
  onCancel,
  activityGroup
}: {
  activityGroupId?: string;
  attendee: Attendee;
  attendeeFields: Field[];
  client: Client;
  isInModal?: boolean;
  onCreate?: () => void;
  handleSubmit?: (formData: FormDataItem[]) => void;
  onCancel: () => void;
  activityGroup?: ActivityGroup;
}) => {
  const [saveUserAttendee] = useUserSaveAttendee();

  const formSchema = generateAttendeeFormSchema({
    activityGroupId,
    assignToUser: false,
    attendeeData: attendee.fieldData,
    client,
    fieldsData: attendeeFields,
    isAdminUse: false,
    attendee,
    activityGroup
  });

  async function onSubmit(formData: FormDataItem[]) {
    await saveUserAttendee({ id: attendee._id, formData });

    onCreate?.();
  }

  const handleOnCancel = () => {
    onCancel();
  };

  return (
    <FormRenderer
      componentMapper={componentMapper}
      FormTemplate={isInModal ? AttendeeModalFormTemplate : FormTemplate}
      schema={formSchema}
      clearedValue={null}
      onSubmit={handleSubmit || onSubmit}
      onCancel={handleOnCancel}
    />
  );
};
